<template>
  <BasePage :isLoaded="!!Document" disablePreFooter disableFooter>
    <template v-slot:content>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <div class="left-col">
              <h4 class="h4 -sci-gradient my-md-6">Document details</h4>
              <h6>{{ Document.title }}</h6>
              <p class="text-small">{{ Document._createdAt | parseDate }}</p>
              <Cta
                action="download"
                label="Download"
                :download="{ title: Document.title, file: Document.pdf.id }"
              />
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <pdf
              :src="`/media/${Document.pdf.id}`"
              :page="currentPage"
              @num-pages="pageCount = $event"
              @page-loaded="currentPage = $event"
            />
            <div class="d-flex align-center justify-space-between py-4">
              <button @click.stop="changePage(-1)" type="button">Prev</button>
              <span>{{currentPage}}/{{pageCount}}</span>
              <button @click.stop="changePage(1)" type="button">Next</button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '@/organisms/BasePage'
import GetDocument from '@/graphql/GetDocument.gql'
import Cta from '@/molecules/Cta'
import pdf from 'vue-pdf'

export default {
  name: 'DocumentsPage',
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  components: {
    BasePage,
    Cta,
    pdf
  },
  apollo: {
    Document: {
      query: GetDocument,
      variables () {
        return {
          slug: this.slug
        }
      },
      async result (res) {
        if (!res.data.Document) {
          this.$router.push({ name: 'Home' })
        }
      }
    }
  },
  filters: {
    parseDate (value) {
      return new Date(value).toLocaleDateString()
    }
  },
  data () {
    return {
      currentPage: 1,
      pageCount: 0
    }
  },
  methods: {
    changePage (direction) {
      if (this.currentPage > 1 && direction === -1) {
        this.currentPage--
      }
      if (this.currentPage < this.pageCount && direction === 1) {
        this.currentPage++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left-col {
  position: fixed;

  @include breakpoint ('sm-and-down') {
    position: relative;
  }
}

button {
  outline: none;
}
</style>
